import Promisable from "./promisable.service";
import { smartActions } from "redux/slices/smart";
import { getAppDispatch } from "utils/dispatch.util";
import http from "./http.service";
import { modalActions } from "redux/slices/modal";

let url = "/smart";
const SmartService = {
  getOne: async (id: string, source: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));
    http.setJWT();

    const [success]: any = await Promisable.asPromise(
      http.get(url + `/${id}?source=${source}`)
    );
    if (success?.data) {
      dispatch?.(smartActions.setBooking(success.data?.data));
    }

    dispatch?.(modalActions.setLoading(false));
  },
  getTimeLine: async () => {
    const dispatch = getAppDispatch();
    dispatch?.(smartActions.setLoading(true));
    http.setJWT();

    const [success]: any = await Promisable.asPromise(
      http.get(url + `/timeLine`)
    );
    if (success?.data) {
      const { groups, items, shift } = success.data.data;
      dispatch?.(smartActions.setTimeLine({ groups, items, shift }));
    }

    dispatch?.(smartActions.setLoading(false));
  },
  addShift: async (data: any, zone: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));
    http.setJWT();
    const [success]: any = await Promisable.asPromise(
      http.post(`/shift`, data)
    );
    if (success.data) {
      const { shift } = success.data.data;
      dispatch?.(smartActions.addShift({ shift, zone }));
      dispatch?.(modalActions.closeModal());
      dispatch?.(modalActions.setLoading(false));
    }
  },
  updateShift: async (id: any, data: any, zone: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));
    http.setJWT();
    const [success]: any = await Promisable.asPromise(
      http.patch(`/shift/${id}`, data)
    );
    if (success.data) {
      const { shift } = success.data.data;
      dispatch?.(smartActions.updateShift({ shift, id, zone }));
      dispatch?.(modalActions.closeModal());
      dispatch?.(modalActions.setLoading(false));
    }
    dispatch?.(modalActions.setLoading(false));
  },
  cancelShift: async (id: any, zone: string, everyday?: any, shiftId?: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));
    http.setJWT();
    const [success]: any = await Promisable.asPromise(
      http.delete(`/shift/${id}?everyday=${everyday}`)
    );
    if (success.data) {
      const { shift } = success.data.data;

      dispatch?.(
        smartActions.cancelShift({ slotId: id, shiftId, zone, shift })
      );
      dispatch?.(modalActions.closeModal());
      dispatch?.(modalActions.setLoading(false));
    }
    dispatch?.(modalActions.setLoading(false));
  },
  cancelAllShift: async (id: any, zone: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));
    http.setJWT();
    const [success]: any = await Promisable.asPromise(
      http.delete(`/shift/all/${id}`)
    );
    if (success.data) {
      dispatch?.(smartActions.cancelShift({ driverId: id, zone }));
      dispatch?.(modalActions.closeModal());
      dispatch?.(modalActions.setLoading(false));
    }
  },
};

export default SmartService;
