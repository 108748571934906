// export { default } from "./AddRolesPermissionsForm";
export interface Permissions {
  name: String;
  read: Boolean | null;
  write: Boolean | null;
  delete: Boolean | null;
  readRoutes: any[];
  writeRoutes: any[];
  deleteRoutes: any[];
}

export const permissionCheckList = [
  {
    name: "dashboard",
    read: false,
    write: null,
    delete: null,
    readRoutes: ['/dashboard'],
    writeRoutes: [],
    deleteRoutes: []
  },
  {
    name: "trip",
    read: false,
    write: false,
    delete: false,
    readRoutes: ['/bookings', '/booking-details/:id', '/events', '/event-details/:id', '/booking-ratings'],
    writeRoutes: ['/add-booking', '/update-booking/:id'],
    deleteRoutes: []
  },
  {
    name: "user",
    read: false,
    write: false,
    delete: false,
    readRoutes: ['/users', '/user-details/:id', '/user-points/:id'],
    writeRoutes: ['/add-user'],
    deleteRoutes: []
  },
  {
    name: "driver",
    read: false,
    write: false,
    delete: false,
    readRoutes: ['/chauffeurs', '/chauffeur-details/:id',],
    writeRoutes: ['/add-chauffeur', '/update-chauffeur/:id'],
    deleteRoutes: []
  },
  {
    name: "vehicle",
    read: false,
    write: false,
    delete: false,
    readRoutes: ['/vehicles', '/vehicle-details/:id'],
    writeRoutes: ['/add-vehicle', '/update-vehicle/:id'],
    deleteRoutes: []
  },
  {
    name: "model",
    read: false,
    write: false,
    delete: false,
    readRoutes: ['/models',],
    writeRoutes: ['/add-model', '/update-model/:id'],
    deleteRoutes: []
  },
  {
    name: "class",
    read: false,
    write: false,
    delete: false,
    readRoutes: ['/class',],
    writeRoutes: ['/add-class', '/update-class/:id'],
    deleteRoutes: []
  },
  {
    name: "availability",
    read: false,
    write: false,
    delete: false,
    readRoutes: ['/availability',],
    writeRoutes: [],
    deleteRoutes: []
  },
  {
    name: "zone",
    read: false,
    write: false,
    delete: false,
    readRoutes: ['/Zone',],
    writeRoutes: ['/add-zone', '/update-zone/:id'],
    deleteRoutes: []
  },
  {
    name: "partner",
    read: false,
    write: false,
    delete: false,
    readRoutes: ['/partners', '/partner-details/:id'],
    writeRoutes: ['/add-partner', '/update-partner/:id'],
    deleteRoutes: []
  },
  {
    name: "partner_requests",
    read: false,
    write: false,
    delete: false,
    readRoutes: ['/partner-requests',],
    writeRoutes: [],
    deleteRoutes: []
  },
  {
    name: "company",
    read: false,
    write: false,
    delete: false,
    readRoutes: ['/companies', '/company-details/:id'],
    writeRoutes: ['/add-company', '/update-company/:id', '/add-company-user'],
    deleteRoutes: []
  },
  {
    name: "company_requests",
    read: false,
    write: false,
    delete: false,
    readRoutes: ['/company-requests'],
    writeRoutes: [],
    deleteRoutes: []
  },
  {
    name: "campaign",
    read: false,
    write: false,
    delete: false,
    readRoutes: ['/campaigns', '/campaign-details/:id'],
    writeRoutes: ['/add-campaign', '/update-campaign/:id'],
    deleteRoutes: []
  },
  {
    name: "price",
    read: false,
    write: false,
    delete: false,
    readRoutes: ['/pricing'],
    writeRoutes: ['/add-pricing', '/update-pricing/:id'],
    deleteRoutes: []
  },
  {
    name: "invoice",
    read: false,
    write: false,
    delete: false,
    readRoutes: ['/partner-invoices', '/company-invoices', '/customer-invoices'],
    writeRoutes: ['/add-invoices'],
    deleteRoutes: []
  },
  {
    name: "contact",
    read: false,
    write: false,
    delete: false,
    readRoutes: ['/contacts'],
    writeRoutes: [],
    deleteRoutes: []
  },
  {
    name: "request_history",
    read: false,
    write: null,
    delete: null,
    readRoutes: ['/search-history'],
    writeRoutes: [],
    deleteRoutes: []
  },
  {
    name: "report",
    read: false,
    write: false,
    delete: false,
    readRoutes: ['/reported-problems'],
    writeRoutes: [],
    deleteRoutes: []
  },
  {
    name: "dynamics",
    read: false,
    write: false,
    delete: false,
    readRoutes: ['/dynamics'],
    writeRoutes: [],
    deleteRoutes: []
  },
  {
    name: "role",
    read: false,
    write: false,
    delete: false,
    readRoutes: ['/roles', '/update-role/:id'],
    writeRoutes: ['/add-role', '/add-role-permission', '/update-role-permission/:id'],
    deleteRoutes: []
  },
  {
    name: "car_movement",
    read: false,
    write: false,
    delete: false,
    readRoutes: ['/car-movement' ],
    writeRoutes: ['/add-car-movement', '/update-car-movement/:id'],
    deleteRoutes: []
  },
  {
    name: "commission",
    read: false,
    write: false,
    delete: false,
    readRoutes: ['/commission' ],
    writeRoutes: [],
    deleteRoutes: []
  },

];

export interface IRoute {
  path: string;
  name: string;
}


// export const private_routes: IRoute[] = [

//   { path: "/roles",}, 
//   { path: "/add-role", },
//   { path: "/update-role/:id", },

//   { path: "/add-company-user",  },
//   { path: "/add-role-permission",  },

//   { path: "/bookings",name:"trip" },
//   { path: "/add-booking" ,name:"trip"},
//   { path: "/update-booking/:id",name:"trip" },
//   { path: "/booking-details/:id" ,name:"trip"},

//   { path: "/users", name:"user"},
//   { path: "/add-user", name:"user"},
//   { path: "/user-points/:id", name:"user" },
//   { path: "/user-details/:id",  name:"user"},

//   { path: "/chauffeurs",name:"driver" },
//   { path: "/add-chauffeur",  name:"driver"},
//   { path: "/update-chauffeur/:id",  name:"driver"},
//   { path: "/chauffeur-details/:id", name:"driver"},

//   { path: "/vehicles", name:"vehicle" },
//   { path: "/add-vehicle", name:"vehicle"},
//   { path: "/update-vehicle/:id",name:"vehicle" },
//   { path: "/vehicle-details/:id", name:"vehicle"},

//   { path: "/models",name:"model"} ,
//   { path: "/add-model",  name:"model"},
//   { path: "/update-model/:id",  name:"model"},

//   { path: "/class",name:"class"  },
//   { path: "/add-class",  name:"class"},
//   { path: "/update-class/:id",  name:"class"},
//   { path: "/availability",  name:"class"},

//   { path: "/city",name:"city"},
//    { path: "/add-city", name:"city"},
//   { path: "/update-city/:id", name:"city"},

//   { path: "/partners", name:"partner"},
//   { path: "/add-partner", name:"partner"},
//   { path: "/update-partner/:id",name:"partner" },
//   { path: "/partner-details/:id",  name:"partner"},

//   { path: "/partner-requests",  },

//   { path: "/companies",  },
//   { path: "/add-company", },
//   { path: "/update-company/:id", },
//   { path: "/company-details/:id",  },

//   { path: "/company-requests",  },

//   { path: "/campaigns",  },
//   { path: "/add-campaign",  },
//   { path: "/update-campaign/:id",  },
//   { path: "/campaign-details/:id",  },

//   { path: "/pricing", },
//   { path: "/add-pricing", },
//   { path: "/update-pricing/:id", },

//   { path: "/partner-invoices",  },
//   { path: "/company-invoices",  },

//   { path: "/events", },
//   { path: "/event-details/:id",  },

//   { path: "/contacts",  },
//   { path: "/booking-ratings",  },
//   { path: "/search-history",  },
//   { path: "/reported-problems",  },

//   { path: "/dynamics",  },
//   { path: "/customer-invoices",  },
//   { path: "/add-invoices", },



// ];

// export const permissionRoutesList = [
//   {
//     name: "trip",
//     read: false,
//     write: false,
//     delete: false,
//   },
//   {
//     name: "user",
//     read: false,
//     write: false,
//     delete: false,
//   },
//   {
//     name: "invoice",
//     read: false,
//     write: false,
//     delete: false,
//   },
//   {
//     name: "company",
//     read: false,
//     write: false,
//     delete: false,
//   },
//   {
//     name: "partner",
//     read: false,
//     write: false,
//     delete: false,
//   },
// ];
